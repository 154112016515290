const CARD_METHOD = 'Cartão'
const BILLET_METHOD = 'Boleto'

const NOT_PAID_STATUS = 'NOT_PAID'
const WAITING_PAYMENT_STATUS = 'WAITING_PAYMENT'
const WAITING_CONFIRM_STATUS = 'WAITING_CONFIRM'
const PAID_STATUS = 'PAID'
const CANCELED_STATUS = 'CANCELED'
const FAILURE_STATUS = 'FAILURE'
const OVERDUE_STATUS = 'OVERDUE'
const INTEGRATION_STATUS = 'INTEGRATION'
const PRE_CAPTURED_STATUS = 'PRE_CAPTURED'
const LOSS_STATUS = 'LOSS'
const WAITING_GATEWAY_STATUS = 'WAITING_GATEWAY'
const WAITING_NFE_STATUS = 'WAITING_NFE'

const NOT_PAID_LABEL = 'NÃO PAGO'
const WAITING_PAYMENT_LABEL = 'AGUARDANDO PAGAMENTO'
const WAITING_CONFIRM_LABEL = 'AGUARDANDO CONFIRMAÇÃO'
const PAID_LABEL = 'PAGO'
const CANCELED_LABEL = 'CANCELADO'
const FAILURE_LABEL = 'FALHA'
const OVERDUE_LABEL = 'VENCIDO'
const INTEGRATION_LABEL = 'INTEGRAÇÃO'
const LOSS_LABEL = 'PERDA'
const WAITING_GATEWAY_LABEL = 'AGUARDANDO GATEWAY'
const WAITING_NFE_LABEL = 'AGUARDANDO NOTA FISCAL'

const STATUS_NFE = {
  '001': 'Aguardando envio para a prefeitura.',
  '002': 'Enviado para a prefeitura, aguardando processamento.',
  '003': 'Processado com erro.',
  '004': 'Processado com sucesso.',
  '005': 'Cancelado com sucesso.',
}

const STEPS_LISTS = [
  {
    step: 'init_payment',
    description: 'Ordem de pagamento criada.',
  },
  {
    step: 'notify_gateway',
    description: 'Enviado os dados para o gateway de pagamento.',
  },
  {
    step: 'factored',
    description: 'Faturado no OMIE.',
  },
  {
    step: 'issued_nfe',
    description: 'Nota fiscal solicitada.',
  },
  {
    step: 'nfe_available',
    description: 'Nota fiscal disponível.',
  },
  {
    step: 'send_charge_client',
    description: 'Cobrança enviada para cliente.',
  },
  {
    step: 'confirmation_receivable_omie',
    description: 'baixa em conta a receber.',
  },
]

const STATUSES = {
  CANCELED_STATUS,
  FAILURE_STATUS,
  NOT_PAID_STATUS,
  OVERDUE_STATUS,
  PAID_STATUS,
  WAITING_CONFIRM_STATUS,
  WAITING_PAYMENT_STATUS,
  INTEGRATION_STATUS,
  PRE_CAPTURED_STATUS,
  LOSS_STATUS,
  WAITING_NFE_STATUS,
  WAITING_GATEWAY_STATUS,
}

const STATUSES_LABELS = {
  CANCELED_LABEL,
  FAILURE_LABEL,
  NOT_PAID_LABEL,
  OVERDUE_LABEL,
  PAID_LABEL,
  WAITING_CONFIRM_LABEL,
  WAITING_PAYMENT_LABEL,
  INTEGRATION_LABEL,
  LOSS_LABEL,
  WAITING_NFE_LABEL,
  WAITING_GATEWAY_LABEL,
}

const TYPE_BILLING_DAY_DYNAMIC = 'dynamic'
const TYPE_BILLING_DAY_FIXED = 'fixed'

const PAYMENT_METHODS = [
  {
    id: 'Transferência bancaria',
    name: 'Transferência bancaria',
  },
  { id: 'Boleto', name: 'Boleto' },
  { id: 'Cartão', name: 'Cartão' },
  { id: 'Pix', name: 'Pix' },
]

const GATEWAYES = [
  { id: 'omie', name: 'OMIE' },
  { id: 'superfin', name: 'Superfin' },
]

const STATUS_OPTIONS = [
  {
    id: NOT_PAID_STATUS,
    name: NOT_PAID_LABEL,
  },
  { id: WAITING_PAYMENT_STATUS, name: WAITING_PAYMENT_LABEL },
  { id: WAITING_CONFIRM_STATUS, name: WAITING_CONFIRM_LABEL },
  { id: PAID_STATUS, name: PAID_LABEL },
  { id: CANCELED_STATUS, name: CANCELED_LABEL },
  { id: FAILURE_STATUS, name: FAILURE_LABEL },
  { id: OVERDUE_STATUS, name: OVERDUE_LABEL },
  { id: INTEGRATION_STATUS, name: INTEGRATION_LABEL },
  { id: LOSS_STATUS, name: LOSS_LABEL },
  { id: WAITING_NFE_STATUS, name: WAITING_NFE_LABEL },
  { id: WAITING_GATEWAY_STATUS, name: WAITING_GATEWAY_LABEL },
]

const CANCEL_MESSAGE = {
  omie:
    'Essa ação somente troca o status da ordem de pagamento para ' +
    'cancelado, não executa nenhuma ação no OMIE.',
  superfin:
    'Essa ação vai mudar o status da ordem de pagamento para cancelado e ' +
    'sera solicitado o cancelamento no Superfin, mas não executa nenhuma ' +
    'ação no OMIE.',
}

const DESCRIPTION_ORDERS_OVERDUE = {
  TOMORROW: 'Pagamentos a vencer',
  TODAY: 'Pagamentos em atraso',
  YESTERDAY: 'Pagamentos em atraso',
}

const paymentOrder = {
  CARD_METHOD,
  BILLET_METHOD,
  STATUSES,
  STATUSES_LABELS,
  STATUS_NFE,
  STEPS_LISTS,
  TYPE_BILLING_DAY_DYNAMIC,
  TYPE_BILLING_DAY_FIXED,
  PAYMENT_METHODS,
  GATEWAYES,
  STATUS_OPTIONS,
  CANCEL_MESSAGE,
  DESCRIPTION_ORDERS_OVERDUE,
}

export default paymentOrder
